h1 {
  font-weight: 400;
  line-height: 1.2;
}
p {
  font-size: 1.125rem;
}
h1,
p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

label {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}
button {
  border: none;
}
.container {
  width: 100%;
  margin: 3.125rem auto 0 auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
.header {
  padding: 0 0.625rem;
  margin-bottom: 1.875rem;
}
.description {
  font-style: italic;
  font-weight: 200;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}
.post-job {
  text-align: center;
}

form {
  background: #f9f9f9;
  padding: 2.5rem 0.625rem;
  border-radius: 0.25rem;
}

@media (min-width: 480px) {
  form {
    padding: 2.5rem;
  }
}

.form-group {
  margin: 0 auto 1.25rem auto;
  padding: 0.25rem;
}

.form-control {
  box-sizing: border-box;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
}

.form-control:focus {
  border-color: 70bdgg;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.input-radio{
  display: inline-block;
  margin-right: 0.625rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
}

.input-textarea {
  min-height: 120px;
  width: 70%;
  padding: 0.625rem;
  resize: vertical;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background: Green;
  color: White;
  border-radius: 2px;
  cursor: pointer;
}
