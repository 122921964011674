.job-filter{
    display: flex;
    flex-direction: column;
}
.job-search{
    display: flex;
}
.search-box{
  margin: 25px 10px 0px 5px;
  padding:5px;
  font-size: 19px;
}
.job-search{
  margin-top: 10px;
}
.search-term{
  padding: 10px;
  width: 180px;
}
.job-category{
  margin-top: 39px;
  font-size: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: #091e5a;
}
.job-category>ul{
  list-style: none;
  padding: 8px;
  cursor: pointer;
}
.job-category ul >li{
  opacity: 0.8;
  margin-top: 10px;
  display: flex;
  gap: 4px;
}
.job-category ul >li:hover{
color: blue;
transform: scale(1.02);
transition: 200ms ease;
}
.filter-page{
  font-size: 30px;
}
@media (max-width:855px)
{
  .search-box{
    font-size: 8px;
  }
  .job-category{
    font-size: 10px;
  }
  .job-category ul >li{
    font-size: 10px;
    display: flex;
    gap: 4px;
  }
  .search-term{
    padding: 2px;
  }
}
@media (max-width:640px){
  .job-card{
    display: flex;
    flex-wrap: wrap;
    width: 108%;
  }
 .job-section{
  flex-direction: column-reverse;
 }
 .job-search{
  display: flex;
  justify-content: center;
 }
 .search-term{
  padding: 1px;
  width: 100px;
 }
 .search{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2px;
 }
 ::placeholder{
  font-size: 10px;
 }
 .job-button{
  display: flex;
 }
 .filter{
  display: flex;
  justify-content: center;
  gap: 50px;
 }
 .job-category{
  width: 100px;
 }
 .search>h3{
  text-align: center;
  font-size: 10px;
 }
 .job-detail{
  width: 180px;
}
}